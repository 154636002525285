import axios from 'axios'
class GoodsService {

    getCategoryGoods() {
        return axios.get(`/category`)
            .then(response => {
                return response.data;
            });
    }

  getGoods(categoryId,line_uid, inputSearch) {
    if (inputSearch !== "") {
      // return axios.get(`/goods/category/${categoryId}/U690e93ea9c5bdc0d105896b8b8f0da47?itemName=${inputSearch}`)
      return axios.get(`/goods/category/${categoryId}/${line_uid}?itemName=${inputSearch}`)
      .then(response => {
        return response.data;
      });
    } else {
      // return axios.get(`/goods/category/${categoryId}/U690e93ea9c5bdc0d105896b8b8f0da47`)
      return axios.get(`/goods/category/${categoryId}/${line_uid}?itemName=${inputSearch}`)
      .then(response => {
        return response.data;
      });
    }
  }

}

export default new GoodsService();