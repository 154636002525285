<template>
  <div class="shopping-goods">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6 mb-8">
          <HeaderTitlePage title="วัตถุดิบ" />
          <v-tabs
            background-color="white"
            color="#2E2253"
            center-active
            centered
            show-arrows
          >
            <vue-element-loading
              :active="isLoadCat"
              spinner="line-scale"
              color="#EF4035"
            />
            <v-tabs-slider color="#2E2253"></v-tabs-slider>
            <v-tab
              v-for="(item, index) in itemsCategoryIngredient"
              :key="index"
              @click.prevent="getGoods(index, item.id)"
            >
              <div>
                <vue-element-loading
                  :active="isLoadItems && index === indexCat"
                  spinner="line-scale"
                  color="#EF4035"
                />
                {{ item.categoryName }}
              </div>
              <!-- <v-btn :loading="isLoadData" :ripple="false" text id="no-background-hover">
                {{item.categoryName}}
              </v-btn> -->
            </v-tab>
          </v-tabs>
            <form v-on:submit.prevent="getGoods">
          <div class="my-3 mx-4 d-flex align-center wrapper-search">
              <input
                type="text"
                class="input-search"
                placeholder="ค้นหาวัตถุดิบ"
                v-model="inputSearchGood"
              />
              <v-btn class="icon-search" @click.prevent="getGoods">
                <v-icon color="white">mdi-magnify</v-icon>
              </v-btn>

          </div>
            </form>

          <v-container fluid class="pt-0">
            <v-col cols="12" class="pt-0 px-3">
              <div
                class="text-center mt-7 red--text"
                v-if="allDataItems.length == 0"
              >
                ไม่มีรายการวัตถุดิบ
              </div>
              <v-row>
                <vue-element-loading
                  :active="isLoadItems"
                  spinner="line-scale"
                  color="#EF4035"
                  class="mt-3"
                />
                <v-col
                  class="px-1 py-1"
                  cols="6"
                  sm="6"
                  v-for="(item, i) in allDataItems"
                  :key="i"
                >
                  <div
                    class="product-items"
                    @click.prevent="setGoodsDetail(item)"
                  >
                    <div class="wrapper-image">
                      <!-- <img
                        width="100%"
                        height="140px"
                        :src="item.imagePath"
                        alt="Image Items"
                      /> -->
                      <v-img
                        :aspect-ratio="4 / 3"
                        :src="item.imagePath"
                      ></v-img>
                    </div>
                    <div class="wrapper-menu-all pt-1 pb-2 px-1">
                      <h2>{{ item.itemName }}</h2>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-container>
      <BottomBar />
    </v-app>
  </div>
</template>

<script>
import HeaderTitlePage from "@/components/HeaderTitlePage.vue";
import BottomBar from "@/components/BottomBar.vue";

import GoodsService from "@/services/goods.service";
export default {
  name: "shopping-goods",
  components: {
    HeaderTitlePage,
    BottomBar,
  },
  data() {
    return {
      isLoadItems: false,
      isLoadCat: false,
      itemsCategoryIngredient: [],
      allDataItems: [],
      indexCat: null,
      dataInfoQuantity: {
        id: null,
        imagePath: "",
        nameTh: "",
        nameEng: "",
        price: "",
        unit: "",
        supplierID: null,
      },
      inputSearchGood: "",
      catId: 1,
    };
  },
  created() {
    this.getCategory();
    this.getGoods();
  },
  methods: {
    getCategory() {
      // this.isLoadItems = true;
      this.isLoadCat = true;
      GoodsService.getCategoryGoods()
        .then((res) => {
          console.log(res);
          this.isLoadCat = false;
          this.itemsCategoryIngredient = res;
        })
        .catch((err) => {
          this.isLoadCat = false;
          console.log(err);
        });
    },
    getGoods(index, id,) {
      if (id !== this.catId) {
        if (id === undefined) id = 1;
        this.catId = id;
        this.indexCat = index;

        this.isLoadItems = true;
        GoodsService.getGoods(this.catId, this.$store.getters.getLineUid, this.inputSearchGood)
          .then((res) => {
            this.isLoadItems = false;
            console.log(res);
            this.allDataItems = res;
          })
          .catch((err) => {
            this.isLoadItems = false;
            console.log(err);
          });
      }
    },
    async setGoodsDetail(item) {
      console.log(item);
      await this.$store.dispatch("setGoodsDetail", item);
      await this.$router.push("/goods-detail");
    },

    // searchGood() {
    //   console.log(this.inputSearchGood);
    //   this.isLoadItems = true;
    //   axios
    //     .get(
    //       `/goods/searchall?itemName=${this.inputSearchGood}&categoryId=${this.catId}&pageNumber=&pageSize=&sort=ITEM_NAME`,
    //       {
    //         headers: {
    //           "Content-Type": "application/json;charset=UTF-8",
    //           "Access-Control-Allow-Origin": "*",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log(res);
    //       this.isLoadItems = false;
    //       this.allDataItems = res.data;
    //     })
    //     .catch((err) => {
    //       this.isLoadItems = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style scoped>
.wrapper-list-menu {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.wrapper-menu-sub {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-menu-all {
  text-align: center;
}
.wrapper-menu-sub h4 {
  font-weight: 400;
  font-size: 14px;
}
.wrapper-menu-all h2 {
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-quantity {
  width: 40px;
  border: 1px solid rgb(167, 167, 167);
  text-align: center;
  border-radius: 5px;
}
.wrapper-search {
  position: relative;
}
.input-search {
  border-radius: 3px !important;
  box-shadow: none !important;
  width: 100%;
  border: 1px solid rgb(165, 165, 165);
  padding: 4px 5px;
  font-size: 14px;
  outline: none;
  caret-color: #2e2253;
}
.icon-search {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 0px !important;
  height: 31px !important;
  box-shadow: none !important;
  background: #2e2253 !important;
}
.product-items {
  border: 1px solid rgb(209, 209, 209);
  width: 100%;
  /* height: 210px; */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* **********quantity************ */
.bg-quantity {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0, 0.5);
  z-index: 5;
  transition: 0.3s;
  display: none;
}
.wrapper-quantity {
  width: 90%;
  background: #fff;
  position: fixed;
  top: -400px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: 0.4s;
  z-index: 6;
}
.quantity-relative {
  position: relative;
}
.wrapper-btn-close {
  position: absolute;
  top: -12px;
  right: -12px;
}
.all-data-quantity h1 {
  font-weight: 500;
  font-size: 20px;
  color: #292929;
}
.all-data-quantity h2 {
  font-weight: 500;
  font-size: 18px;
  color: #343434;
}

/* **********quantity************ */

#no-background-hover::before {
  background-color: transparent !important;
}
</style>