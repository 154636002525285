<template>
  <div>
    <div class="bottom-bar">
      <div class="wrapper-bar">
        <div class="mr-2 wrapper-info">
          <span>{{ this.$store.getters.getShoppingCart.length }} รายการ</span>
          <h2>
            รวมเป็นเงิน
            {{
              this.$store.getters.getShoppingCart.reduce(
                (acc, curr) => acc + curr.summaryVat,
                0
              )
            }}
            บาท
          </h2>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            @click.prevent="viewListOrder()"
            color="primary"
            class="btn-addtocart"
            >ดูรายการที่เพิ่ม</v-btn
          >
        </div>
      </div>
    </div>

    <div class="wrapper-list-bg" id="wrapper-list-bg">
      <v-card class="wrapper-list" id="wrapper-list">
        <div class="all-list">
          <div
            v-if="itemsAllCart.length == 0"
            class="red--text text-center mt-5"
          >
            ไม่มีรายการที่เพิ่ม
          </div>
          <div v-for="(item, i) in itemsAllCart" :key="i">
            <div class="wrapper-list-menu">
              <div>
                <img width="120px" height="100px" :src="item.imagePath" />
              </div>
              <div class="ml-3 pt-1 wrapper-menu-all">
                <h2>{{ item.name }}</h2>
                <div class="wrapper-menu-sub">
                  <h4>
                    {{ item.unitPrice }} บาท/{{ item.unit }} (VAT
                    {{ item.vat }}%)
                  </h4>
                  <h4>x{{ item.quantity }}</h4>
                </div>
              </div>
            </div>
            <v-divider class="mb-2"></v-divider>
          </div>
        </div>
        <div class="wrapper-btn-seemore">
          <v-btn
            @click.prevent="closeListOrder()"
            class="white--text"
            small
            width="100px"
            color="red"
            >ปิด</v-btn
          >
          <v-btn
            @click.prevent="nextToCart()"
            class="ml-4"
            small
            width="100px"
            color="primary"
            >ไปที่รถเข็น</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
  data() {
    return {
      itemsAllCart: [],
    };
  },
  methods: {
    viewListOrder() {
      this.itemsAllCart = this.$store.getters.getShoppingCart;
      document.getElementById("wrapper-list").style.top = "48%";
      document.getElementById("wrapper-list-bg").style.display = "block";
      document.querySelector("html").style.overflow = "hidden";
    },
    closeListOrder() {
      document.getElementById("wrapper-list").style.top = "-400px";
      document.getElementById("wrapper-list-bg").style.display = "none";
      document.querySelector("html").style.overflow = "auto";
    },
    nextToCart() {
      this.$router.push("/cart");
      this.closeListOrder();
    },
  },
};
</script>

<style>
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px !important;
  background: #fff;
  z-index: 1;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.2);
}
.wrapper-bar {
  height: 55px;
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}
.btn-addtocart {
  width: 120px;
  border-radius: 0px;
  height: 55px !important;
  color: #fff;
}
.wrapper-info {
  text-align: right;
  line-height: 24px;
}
.wrapper-info span {
  color: rgb(59, 59, 59);
}
.wrapper-info h2 {
  font-size: 20px;
  color: rgb(248, 0, 0);
}

.wrapper-list-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 6;
  transition: 0.2s ease-in-out;
  display: none;
  margin: 0;
  overflow-y: hidden;
}
.wrapper-list {
  position: fixed;
  width: 94%;
  height: 80vh;
  top: -400px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  z-index: 7;
  padding: 15px 10px 50px 10px;
  transition: 0.2s ease-in-out;
}
.wrapper-list-menu {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.wrapper-menu-sub h4 {
  font-weight: 400;
}
.wrapper-menu-all {
  width: 99%;
  overflow: hidden;
}
.wrapper-menu-all h2 {
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-list {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  position: relative;
}
.wrapper-btn-seemore {
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 14px;
}
</style>